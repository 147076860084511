import * as React from "react";
import { ReactComponent as HumanoidLogo } from "../assets/humanoid.svg";
import { ReactComponent as HumanoidDelta } from "../assets/logo.svg";
import { ReactComponent as Hamburger } from "../assets/hamburger.svg";
import { ReactComponent as Close } from "../assets/close.svg";

const Header = () => {
  const [showDropdown, setShowDropdown] = React.useState(false);
  return (
    <>
      <header
        className="z-10
		w-full"
      >
        <div
          className="flex 
		justify-between 
		text-lg 
		text-white 
		h-[90px] 
		items-center 
		px-6 
		w-full 
		absolute
		"
        >
          <div className="flex items-center">
            <HumanoidDelta className="mr-6" />
            <HumanoidLogo className="hidden lg:block" />
          </div>
          <div>
            <HumanoidLogo className="lg:hidden" />
            <ul className="flex justify-evenly gap-4 hidden lg:flex">
              <li className="border-b border-neonGreen text-lg hover:cursor-pointer hover:text-neonGreen">
                <a href="/">The big beta test</a>
              </li>
              <li className="border-b border-neonGreen text-lg hover:cursor-pointer hover:text-neonGreen">
                <a href="/instructions">Instructions</a>
              </li>
              <li className="border-b border-neonGreen text-lg hover:cursor-pointer hover:text-neonGreen">
                <a href="/the-app">The app</a>
              </li>
              <li className="border-b border-neonGreen text-lg hover:cursor-pointer hover:text-neonGreen">
                <a href="/ingredients">Ingredients</a>
              </li>
              <li className="border-b border-neonGreen text-lg hover:cursor-pointer hover:text-neonGreen">
                <a href="/faq">FAQ</a>
              </li>
            </ul>
          </div>
          <div className="lg:w-[90px] w-auto">
            {!showDropdown && (
              <Hamburger
                className="lg:hidden cursor-pointer"
                onClick={() => setShowDropdown(true)}
              />
            )}
            {showDropdown && (
              <Close
                className="lg:hidden cursor-pointer"
                onClick={() => setShowDropdown(false)}
              />
            )}
          </div>
        </div>
      </header>
      <div
        className={`top-0 pt-[90px] bg-transparent w-full ${
          showDropdown ? "block" : "hidden"
        } ${showDropdown ? "h-screen block" : ""}`}
      >
        <ul className="flex flex-col text-white mb-10 ml-10 ">
          <li className="py-8 text-left text-lg">
            <a
              className="underline-neonGreen underline hover:cursor-pointer hover:text-neonGreen"
              href="/"
            >
              The big beta test
            </a>
          </li>
          <li className="py-8 text-left text-lg">
            <a
              className="underline-neonGreen underline hover:cursor-pointer hover:text-neonGreen"
              href="/instructions"
            >
              Instructions
            </a>
          </li>
          <li className="py-8 text-left text-lg">
            <a
              className="underline-neonGreen underline hover:cursor-pointer hover:text-neonGreen"
              href="/the-app"
            >
              The app
            </a>
          </li>
          <li className="py-8 text-left text-lg">
            <a
              className="underline-neonGreen underline hover:cursor-pointer hover:text-neonGreen"
              href="/ingredients"
            >
              Ingredients
            </a>
          </li>
          <li className="py-8 text-left text-lg">
            <a
              className="underline-neonGreen underline hover:cursor-pointer hover:text-neonGreen"
              href="/faq"
            >
              FAQ
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Header;

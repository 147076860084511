import * as React from "react";
import HeroImage from "./assets/heroImage.png";
import { ReactComponent as DSplat } from "./assets/deltaSplat.svg";
import { ReactComponent as Arrow } from "./assets/arrow.svg";

import Page from "../components/page";

import Background from "./assets/backgroundHomepage.png"
import CollageTopLeft from "./assets/collageTopLeft.png";
import CollageTopRight from "./assets/collageTopRight.png";
import CollageBottomLeft from "./assets/collageBottomLeft.png";
import CollageBottomRight from "./assets/collageBottomRight.png";
import PhoneImages from "./assets/phoneImages.png";

const Home = () => {
  return (
    <>
      <Page backgroundImage={Background}>
        <div className="flex justify-center md:h-full">
          <div className="relative max-w-[1440px]">
            <DSplat className="absolute top-[15%] left-6 md:left-[50%] md:top-auto md:bottom-[120px] md:scale-[2.1]" />
            <div className=" flex flex-col justify-between md:flex-row-reverse">
              <img alt="" src={HeroImage} className="self-center w-1/2" />
              <div className="flex md:w-1/2 justify-center items-center py-7 md:pr-20">
                <div className="flex flex-col items-center">
                  <div className="text-sm md:text-xl bg-neonGreen w-max p-2 mb-4">
                    the big beta test
                  </div>
                  <div className="text-3xl text-white px-16 md:px-0 md:text-6xl">
                    So glad you're here!
                    We hope you're as excited about this as we are.
                  </div>
                  <Arrow className="mt-5 md:hidden" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white md:flex md:flex-row p-4 text-left md:px-[72px]">
          <div className="text-humanoidPurple text-3xl md:w-1/2 pb-4 w-full">
            <div className="w-full md:w-3/4">
              We are humanoid. We believe that beauty is more than what can be
              seen on the outside: it is about who we are, how we feel, and how
              we show up.
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <div>
              We are developing an ecosystem of connected beauty products that
              will allow you to easily create looks to express your authentic
              self.
            </div>
            <div className="font-bold mt-8 mb-4">Why we need you</div>
            <div>
              We would love your help shaping the direction of the humanoid
              experience. Your feedback will be invaluable to us as we continue
              to develop our app and product range.
            </div>
          </div>
        </div>

        <div className=" bg-white text-left px-4 md:px-[72px] md:pb-[70px]">
          <div className="border-t border-black bg-white" />
          <div className="bg-white text-3xl text-left py-4 md:text-xl md:pt-2">
            What am I signing up for?
          </div>
          <div className="flex bg-white md:pt-2">
            <div className="flex flex-col md:pr-8 md:w-1/2">
              <div className="flex">
                We’ll send you a box with products designed to help you explore
                the humanoid app and create exciting new makeup looks.
                Throughout the beta we will ask for honest feedback about your
                experience.
              </div>

              <div className="flex md:hidden mt-5">
                <div className="flex p-4 bg-[url('home/assets/imageCollageBackground.png')] flex-wrap h-fit">
                  <img alt="" src={CollageTopLeft} className="flex w-1/2" />
                  <img alt="" src={CollageTopRight} className="flex w-1/2" />
                  <img alt="" src={CollageBottomLeft} className="flex w-1/2" />
                  <img alt="" src={CollageBottomRight} className="flex w-1/2" />
                </div>
              </div>

              <div className="flex flex-col my-5 py-4 text-left text-humanoidPurple">
                <div className="font-bold">In the humanoid box:</div>
                <ul className="list-disc mx-[30px]">
                  <li>5 x all over color sticks</li>
                  <li>1 x felt tipped liquid liner</li>
                  <li>2 x eye liner pencils</li>
                  <li>6 x brush liquid liners</li>
                  <li>1 x body glitter</li>
                  <li>1 x metallic face paint</li>
                  <li>4 x detailing brushes</li>
                  <li>11 x humanoid stencils</li>
                </ul>
              </div>
              <div className="font-bold pb-3">Research</div>
              <ul className="list-disc mx-[30px]">
                <li>
                  The test will run for{" "}
                  <span className="font-bold">four weeks</span>. At the end of
                  each week, we will send you a brief survey asking about your
                  experience.
                </li>
                <li>
                  Participants will be asked to opt in for{" "}
                  <span className="font-bold">one-on-one interviews</span> with
                  a member of our team.
                </li>
                <li>
                  We will stay in touch via email and{" "}
                  <span className="font-bold">Discord.</span> Feel free to ask
                  us anything, any time!
                </li>
              </ul>
            </div>

            <div className="hidden md:w-1/2 md:flex">
              <div className="flex p-4 bg-[url('home/assets/imageCollageBackground.png')] md:flex-wrap h-fit">
                <img alt="" src={CollageTopLeft} className="flex md:w-1/2" />
                <img alt="" src={CollageTopRight} className="flex md:w-1/2" />
                <img alt="" src={CollageBottomLeft} className="flex md:w-1/2" />
                <img
                  alt=""
                  src={CollageBottomRight}
                  className="flex md:w-1/2"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[url('home/assets/mushroom.png')] bg-cover text-left px-4 py-12 md:p-[72px]">
          <div className="border-t border-black bg-white" />
          <div className="text-2xl pt-2">The humanoid app</div>

          <div className="flex flex-wrap mt-12 md:mt-4">
            <div className="md:py-[40px] md:pr-[60px] md:w-1/2">
              <img alt="" src={PhoneImages} />
            </div>
            <div className="flex flex-col justify-center items-center mt-8 md:w-1/2">
              <div className="text-lg pt-4">
              With the humanoid app, you will have access to a wide assortment of makeup
              looks and tutorials.
              </div>
              <div className="text-lg pt-4">
                You can select looks by color or time needed to create a look.
                Or, you can set your vibe and get looks that fit how you feel today.{" "}
              </div>
              <div className="text-lg pt-4">
                You can also see looks created by your fellow beta testers, and view
                the history of your completed looks at any time.
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white text-left px-4 py-12 md:p-[72px]">
          <div className="border-t border-black bg-white" />
          <div className="text-2xl mt-5"> Not yet a beta tester? </div>
          <div className="flex flex-col md:flex-row mt-5">
            <div className="md:pr-10 md:w-1/2 text-lg">
              {" "}
              We hope you will join us and participate in the beta test. To do
              so, fill out the signup form linked in the email invitation you
              received from research@wearehumanoid.com. The beta test is
              currently for iPhones 10 and above only. We’ll get to other
              devices ASAP!
            </div>
            <div className="text-humanoidPurple pt-11 md:pt-0 md:w-1/2">
              Didn’t receive an invite? Send an email to
              research@wearehumanoid.com
            </div>
          </div>
        </div>
      </Page>
    </>
  );
};

export default Home;

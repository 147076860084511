import * as React from "react";
import Image1 from "../assets/footer/image-1.png";
import Image2 from "../assets/footer/image-2.png";
import Image3 from "../assets/footer/image-3.png";
import Image4 from "../assets/footer/image-4.png";
import Image5 from "../assets/footer/image-5.png";
import HumanoidH from "../assets/footer/humanoid-h.png";
import HumanoidLogoArtistic from "../assets/footer/humanoid-logo-artistic.png";
import { ReactComponent as HumanoidLogo } from "../assets/humanoid.svg";
import { ReactComponent as HumanoidDelta } from "../assets/logo.svg";
const Footer = () => {
  return (
    <footer className="w-full  max-w-[1440px] lg:px-20  m-auto">
      <div className="my-10 border p-2 border-white">
        <div className="flex justify-between items-center">
          <HumanoidLogo />
          <div className="text-white flex">
            <a className="underline mr-4 ml-2" href="/app-privacy">
              app privacy policy
            </a>
            <a className="underline mr-4 ml-2" href="/privacy">
              privacy policy
            </a>
            <a className="underline mr-4 ml-2" href="/tos">
              terms of use
            </a>
            <div>(c) 2022</div>
          </div>
          <HumanoidDelta />
        </div>
      </div>
      <div className="flex flex-wrap sm:grid sm:grid-cols-[1fr_1fr_2fr_1fr_1fr]">
        <div className="relative flex w-1/2 sm:w-full sm:block">
          <div className="absolute top-0 flex justify-center items-center w-full h-full">
            <img alt="" src={HumanoidH} className="w-1/2 h-1/2" />
          </div>

          <img className="w-full" alt="" src={Image1} />
        </div>
        <img
          alt=""
          src={Image2}
          className="w-1/2 relative flex grow sm:block  sm:w-full"
        />
        <div className="relative flex grow sm:block">
          <div className="absolute top-0 flex justify-center items-center w-full h-full">
            <img alt="" src={HumanoidLogoArtistic} className="w-3/4" />
          </div>
          <img alt="" className="w-full" src={Image3} />
        </div>
        <img className="w-full hidden sm:block" alt="" src={Image4} />
        <img className="w-full hidden sm:block" alt="" src={Image5} />
      </div>
    </footer>
  );
};

export default Footer;

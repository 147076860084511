import * as React from "react";

interface PageProps { 
  backgroundImage: any;
  children: React.ReactNode;
}

const Page : React.FC<PageProps> = props =>  {
  return(
     <>
      <div className="fixed top-0 z-[-1] h-screen flex flex-col w-screen">
        <img className="h-screen" src={props.backgroundImage}/>
      </div>
      <div className="z-10 md:px-20 max-w-[1440px] m-auto pt-[90px]">
        {props.children}
      </div>
    </>
  )
};

export default Page;

import * as React from "react";
import Page from "../components/page";
import Background from "./assets/background.png" 

const PrivacyPolicy = () => {
  return (
    <>
      <Page backgroundImage={Background}>
        <div className="bg-white pl-3 pt-3 md:pl-[70px] md:pr-[454px] md:py-[54px]">
          <div className="text-sm md:text-xl bg-neonGreen w-max p-2">
           Privacy Policy
          </div>
	  <div className="text-3xl text-left pt-4">
            Humanoid Privacy Policy
	  </div>
	  <div className="text-xl text-left pt-4">
    Information that is gathered from visitors
	  </div>
	  <div className="text-left pt-4">
    In common with other websites, log files are stored on the web server saving details such as the visitor's IP address, browser type, referring page and time of visit.
Cookies may be used to remember visitor preferences when interacting with the website.
Where registration is required, the visitor's email and a username will be stored on the server.
	  </div>
	  <div className="text-xl text-left pt-4">
    How the Information is used
	  </div>
	  <div className="text-left mt-5">
    The information is used to enhance the vistor's experience when using the website and mobile app to display personalized content and possibly advertising.
E-mail addresses will not be sold, rented or leased to 3rd parties.
E-mail may be sent to inform you of news of our services or offers by us or our affiliates.
	  </div>
	  <div className="text-xl text-left pt-4">
    Visitor Options
	  </div>
	  <div className="text-left mt-5">
    If you have subscribed to one of our services, you may unsubscribe by following the instructions which are included in e-mail that you receive.
You may be able to block cookies via your browser settings but this may prevent you from access to certain features of the website.
          </div>
          <div className="text-xl text-left pt-4">
   Cookies
	  </div>
	  <div className="text-left mt-5">
    Cookies are small digital signature files that are stored by your web browser that allow your preferences to be recorded when visiting the website. Also they may be used to track your return visits to the website.
3rd party advertising companies may also use cookies for tracking purposes.
          </div>
          <div className="text-xl text-left pt-4">
   Data Removal
	  </div>
	  <div className="text-left mt-5">
    If at any point you want your data removed from the system, reach out on discord and we will delete all data associated with your email address.
          </div>
        </div>
      </Page>
    </>
  );
};

export default PrivacyPolicy;

import * as React from "react";
import Page from "../components/page";
import Background from "./assets/background.png" 
import Detail from "../components/detail";

const faqs = [
  {
    question: "How do I connect my product?",
    answer: "Each product has an NFC tag applied to it. You can easily connect your product by tapping the NFC tag at the top of your phone screen around the camera area. You first need to download the humanoid app - you will find the download instructions in the app section of this site.",
  },
  {
    question: "How long is the beta?",
    answer: "The beta will last one month, from July to August",
  },
  {
    question: "Will I get paid?",
    answer:
      "No, but you are getting 200+ dollars in free humanoid products that you can keep after the beta.",
  },
  {
    question: "What is expected of me during the beta?",
    answer:
      "3 things: use our products, respond to the weekly survey, and use our discord to let us know of any issues",
  },
  {
    question: "Can I post about the beta on my social media accounts?",
    answer:
      "Yes! Feel free to talk about your experiences in the beta test and share photos online.",
  },
  {
    question: "Can I share my humanoid products with other people?",
    answer:
      "Totally, we encourage you to let your friends/family try our products. If they are interested in humanoid but didn't get into the beta send us a message on discord and we'll invite them to download the app.",
  },
  {
    question: "When will I get my products?",
    answer:
      "We are planning to start shipping on July __. You will get an email notification with a tracking number when your box is shipped",
  },
  {
    question: "Do I get to keep my beauty products?",
    answer: "Yes! you can keep all of the products we send you.",
  },
  {
    question: "I broke/damaged the product... what do I do?",
    answer:
      "No worries, this is exactly what we want to hear about as we are developing this product. Please take some pictures and contact customer support through discord - tell us the circumstances.",
  },
  {
    question: "Can I get a replacement if one of my products isn't working?",
    answer:
      "Unfortunately, because we had a limited production run for the beta we are not able to replaced damaged products",
  },
  {
    question: "What ingredients are in the spray?",
    answer:
      "You can view the complete ingredient list here, or scan the QR code on the can",
  },
  {
    question: "The products made me breakout, what should I do?",
    answer:
      "If you experience skin irritation please stop using the products and contact us with details.",
  },
  {
    question: "The product got into my eyes",
    answer:
      "Nothing bad can happen but to relieve the discomfort, wash it out with a lot of water, contact customer support to tell us how this happened, so we can learn how to make our product better.",
  },
  {
    question: "I can't find the app in the App Store",
    answer:
      "We're not in the App Store, because we're still in Beta. To download the app, follow the instructions in your invitation email for this Beta, or use the QR code that came with your beauty product. If you can't find your email or the product packaging, contact customer support.",
  },
];

const FAQ = () => {
  return (
    <>
      <Page backgroundImage={Background}>
        <div className="flex flex-col items-center mt-10">
          <div className="text-sm md:text-xl bg-neonGreen w-max p-2 mb-4">
           FAQ 
          </div>
	  <div className="text-3xl text-white">
            Any questions?
	  </div>
	  <div className="text-white pt-5 md:px-20 max-w-[1440px]">
            As we are in beta mode and figuring out a lot of things as we go, we’re sure you’ll have questions. We hope you do! Here’s a list of some FAQs.
We’re also available on Discord, so hit us up!
	  </div>
        </div>
        <div className="z-10 px-3 md:px-20 max-w-[1440px] m-auto pt-[20px]">
          {faqs.map((x, index) => (
            <div className="my-4" key={index}>
              <Detail summary={x.question} colorHex="purple" size="small">
                <div className="text-left p-4">{x.answer}</div>
              </Detail>
            </div>
          ))}
        </div>
      </Page>
    </>
  );
};

export default FAQ;

import * as React from "react";
import { Link } from "react-router-dom";
import Page from "../components/page";
import Background from "./assets/background.png" 

import PhoneImages from "../home/assets/phoneImages.png";
import EmailExample from "./assets/emailExample.png";
import TestflightImage from "./assets/testflight.png";
import TestflightIPhone from "./assets/testflightIPhone.png";
import HumanoidIPhones from "./assets/humanoidIPhones.png";
import { ReactComponent as Arrow } from "./assets/blackArrow.svg";

const TheApp = () => {
  return (
    <>
      <Page backgroundImage={Background}>
        <div className="bg-[url('home/assets/mushroom.png')] bg-cover text-left px-4 py-12 md:p-[72px]">
          <div className="flex flex-col md:flex-row-reverse">

            <div className="md:py-[40px] md:w-1/2">
              <img alt="" src={PhoneImages} />
            </div>

	    <div className="flex flex-col text-center items-center p-5 md:p-12 md:w-1/2">
              <div className="text-sm md:text-xl bg-neonGreen w-max p-2 mb-4">
                the app
              </div>
	      <div className="text-5xl">
          Explore the humanoid app
	      </div>
	      <div className="hidden md:block pt-6 md:px-10">
        The humanoid app provides a large catalog of looks that can all be created in ten
          minutes or less. You can find inspiration by filtering the looks by color, how much
          time you have available or based on your vibe.
	      </div>
        <div className="hidden md:block pt-6 md:px-10">
          AR camera filters help you to quickly try out new looks and when you're ready to create 
          a look, our short step-by-step video guides are easy to follow for any skill level.
	      </div>
        <div className="hidden md:block pt-6 md:px-10">
          You can save your looks in your private journal or share your look on the community page 
          where you can also see what other folks in the beta are doing.
	      </div>
              <Arrow className="mt-5 md:hidden" />
            </div>
          </div>
	</div>
        <div className="bg-white text-left px-4 py-12 md:p-[72px]">
	  <div className="md:hidden">
    The humanoid app provides a large catalog of looks that can all be created in ten
          minutes or less. You can find inspiration by filtering the looks by color, how much
          time you have available, or based on your vibe.
	  </div>
    <div className="md:hidden pt-6 md:px-10">
    AR camera filters help you to quickly try out new looks and when you're ready to create 
    a look, our short step-by-step video guides are easy to follow for any skill level.
	  </div>
    <div className="md:hidden pt-6 md:px-10">
    You can save your looks in your private journal or share your look on the community page 
          where you can also see what other folks in the beta are doing.
	  </div>
	  <div className="text-2xl mt-12 ml-4 md:mt-0">
            How to get the app
	  </div>
	  <div className="flex flex-col md:flex-row">
            <div className="flex flex-col bg-cover bg-[url('theapp/assets/backgroundPanel1.png')] items-center text-left rounded-2xl m-3 md:w-1/3">
              <div className="flex text-humanoidPurple p-6 self-start">
                1. email invite
	      </div>
	      <img alt="example of the Humanoid test" src={EmailExample} className="mt-3"/>
              <div className="mt-10 p-4">
                Once you sign up for the beta, we’ll
send an invite to the humanoid app to the email associated with your apple ID. Look for ‘Humanoid Labs, Testflight’ or similar. 
Can’t find it? Contact us on 
Discord or email us research@wearehumanoid.com.
	      </div>
	    </div>
            <div className="flex flex-col bg-[url('theapp/assets/backgroundPanel2.png')] items-center text-left rounded-2xl m-3 md:w-1/3">
              <div className="flex text-humanoidPurple p-6 self-start">
                2. Testflight from App store
	      </div>
	      <div className="flex">
		<div className="ml-8 w-1/7">
                  <img alt="" src={TestflightImage} className="flex"/>
		  <div className="text-center mt-2">TestFlight</div>
		</div>
		<div className="w-6/7">
                  <img alt="" src={TestflightIPhone} />
		</div>
              </div>
	      <div className="p-4 mt-4">
                We are running our Beta program using the developer tool Testflight. The invite will prompt you to download Testflight from the App store. You can then download the humanoid app from within Testflight. 
              </div>
	    </div>
            <div className="flex flex-col bg-[url('theapp/assets/backgroundPanel3.png')] items-center text-left rounded-2xl m-3 md:w-1/3">
              <div className="text-humanoidPurple p-6">
                3. Register by going through onboarding
	      </div>
              <img alt="" src={HumanoidIPhones} className="flex"/>
              <div className="p-4 mt-4">Once installed, the app will guide you through the process of creating an account. After your account is set up you can start to explore the humanoid world.</div>
	    </div>
          </div>
	  <div className="flex flex-col">
	    <div className="text-humanoidPurple mt-5 text-4xl md:w-1/3">
              Please take a minute to join our discord
	    </div>
	    <div className="mt-5 md:w-1/3">
              Discord is where we will be answering questions and taking feedback throughout the beta. 
              It is also the place where you can interact with other participants in the beta test by
              sharing looks, celebrating their looks, inspiring each other with your experiences and
              thoughts, and more
	    </div>
      <div className="mt-5 md:w-1/3">
              Joining the humanoid Discord will also provide you early access to the humanoid community
              when we launch in May 2023.
	    </div>
	    <a href="https://discord.gg/7pwZmxT87b" className="rounded-full h-[53px] w-[200px] self-center md:self-start">
	      <button type="button" className="mt-7 text-white bg-black font-bold rounded-full h-[53px] w-[200px]" >
                Join our Discord
	      </button>
            </a>
          </div>
        </div>
      </Page>
    </>
  );
};

export default TheApp;

import * as React from "react";
import Page from "../components/page";
import Background from "./assets/background.png";

const PrivacyPolicy = () => {
  return (
    <>
      <Page backgroundImage={Background}>
        <div className="bg-white pl-3 pt-3 md:pl-[70px] md:pr-[454px] md:py-[54px]">
          <div className="font-bold text-3xl text-center pt-4">
            Privacy Policy for Humanoid App
          </div>
          <div className="font-bold text-xl text-left pt-4">
            Last Updated: <span className="font-normal">September 5, 2023</span>
          </div>

          <section className="mt-6 text-left">
            <h2 className="text-xl font-semibold mb-4">
              1. Information We Collect
            </h2>

            <div className="ml-6">
              <h3 className="text-lg font-semibold">
                1.1 Information You Provide
              </h3>
              <ul className="list-disc list-dash list-inside ml-8">
                <li>
                  Apple ID: We require your Apple ID to create and access your
                  Humanoid account.
                </li>
                <li>
                  Nickname: You may add an optional nickname to your account.
                </li>
                <li>
                  Social Handles: If you choose to share your TikTok and/or
                  Instagram handles, we will store this information.
                </li>
                <li>Images: The images you upload to the app.</li>
                <li>Notes: Notes you make on your own images.</li>
                <li>Favorite Looks: A list of looks you save as favorites.</li>
              </ul>
            </div>

            <div className="mt-4 ml-6">
              <h3 className="text-lg font-semibold">
                1.2 Information We Collect When You Use Our Services
              </h3>
              <ul className="list-disc list-dash list-inside ml-8">
                <li>
                  Usage Information: We collect data on how you interact with
                  our services, including which features you use and your
                  interactions with other users.
                </li>
                <li>
                  Head and Face Tracking: When you use our makeup try-on
                  feature, we leverage your camera and Apple's TrueDepth API to
                  track head and face movements, including facial expressions.
                  None of this data leaves your device or is transmitted to
                  external servers or third parties.
                </li>
                <li>
                  Content Information: We collect information related to content
                  you create or share on our platform, such as photos or notes.
                </li>
                <li>
                  Device Information: Information about your device, including
                  hardware and software details.
                </li>
                <li>
                  Information Collected by Cookies and Other Technologies: We
                  use cookies and similar technologies to collect data about
                  your activity, browser, and device.
                </li>
              </ul>
            </div>
          </section>

          <section className="mt-6 text-left">
            <h2 className="text-xl font-semibold mb-4">
              2. How We Use Information
            </h2>
            <p>We use the collected data for the following purposes:</p>
            <ul className="list-disc list-dash ml-6 mt-2">
              <li>
                Personalization of your experience within the app, including our
                AR makeup try-on feature.
              </li>
              <li>Develop, improve, and maintain our app.</li>
              <li>Conduct research to understand user interests and trends.</li>
              <li>Enhance the safety and security of our app.</li>
              <li>
                Enforce our Terms of Service and respond to legal requirements.
              </li>
            </ul>
          </section>

          <section className="mt-6 text-left">
            <h2 className="text-xl font-semibold mb-4">
              3. How We Share Information
            </h2>
            <p>
              We want to clearly share when we do and do not share your
              information.
            </p>

            <div className="ml-6 mt-2">
              <h3 className="text-lg font-semibold">3.1 With Other Users</h3>
              <ul className="list-disc list-dash ml-8">
                <li>
                  Information about you, such as your social handles, may be
                  shared with other users based on your settings.
                </li>
                <li>
                  Photos you set to be shared in-app or with the community.
                </li>
              </ul>
            </div>

            <div className="mt-4 ml-6">
              <h3 className="text-lg font-semibold">3.2 With Our Affiliates</h3>
              <ul className="list-disc list-dash ml-8">
                <li>
                  We do not share information with companies within our
                  corporate family.
                </li>
              </ul>
            </div>

            <div className="mt-4 ml-6">
              <h3 className="text-lg font-semibold">3.3 With Third Parties</h3>
              <ul className="list-disc list-dash ml-8">
                <li>
                  We do not share information with service providers, business
                  partners, or advertisers.
                </li>
                <li>
                  We do not share non-personal, aggregated, or de-identified
                  information.
                </li>
                <li>
                  When required, we may share information for legal, safety, and
                  security reasons.
                </li>
                <li>
                  In case of mergers or acquisitions, your information may be
                  shared with the acquiring company.
                </li>
              </ul>
            </div>
          </section>

          <section className="mt-6 text-left">
            <h2 className="text-xl font-semibold mb-4">
              4. Third-Party Content and Integrations
            </h2>
            <ul className="list-disc list-inside list-dash ml-8">
              <li>
                Our app does not include third-party content and integrations.
              </li>
            </ul>
          </section>

          <section className="mt-6 text-left">
            <h2 className="text-xl font-semibold mb-4">
              5. How Long We Keep Your Information
            </h2>
            <ul className="list-disc list-inside list-dash ml-8">
              <li>
                We retain your information as long as your account remains
                active.
              </li>
            </ul>
          </section>

          <section className="mt-6 text-left">
            <h2 className="text-xl font-semibold mb-4">
              6. Data Storage and Security
            </h2>

            <div className="ml-6 mt-2">
              <h3 className="text-lg font-semibold">
                6.1 Information You Provide
              </h3>
              <ul className="list-disc list-inside list-dash ml-8">
                <li>
                  Data you provide (see section 1.1 for details) are stored on
                  Amazon Web Services servers. We take reasonable measures to
                  secure your information, but no method of transmission or
                  storage is completely secure.
                </li>
              </ul>
            </div>

            <div className="mt-4 ml-6">
              <h3 className="text-lg font-semibold">
                6.2 Information We Collect When You Use Our Services
              </h3>
              <ul className="list-disc list-dash ml-8">
                <li>
                  Usage Information: Is stored on your device and on our
                  servers.
                </li>
                <li>
                  Head and Face Tracking: All data collected through the
                  TrueDepth API is processed exclusively on your device in
                  real-time. None of the TrueDepth data ever leaves your device
                  or is transmitted to our servers or any third parties. We do
                  not store any data collected through the TrueDepth API on your
                  device, our servers, or any external systems.
                </li>
              </ul>
            </div>
          </section>

          <section className="mt-6 text-left">
            <h2 className="text-xl font-semibold mb-4">7. User Rights</h2>
            <ul className="list-disc list-inside list-dash ml-8">
              <li>
                You have control over your information, including preference and
                deletion. Visit our Account Settings to manage your data.
              </li>
            </ul>
          </section>

          <section className="mt-6 text-left">
            <h2 className="text-xl font-semibold mb-4">8. Legal Compliance</h2>
            <ul className="list-disc list-inside list-dash ml-8">
              <li>
                We comply with applicable U.S. privacy laws, including the
                California Consumer Privacy Act (CCPA).
              </li>
            </ul>
          </section>

          <section className="mt-6 text-left">
            <h2 className="text-xl font-semibold mb-4">
              9. Updates to the Privacy Policy
            </h2>
            <ul className="list-disc list-inside list-dash ml-8">
              <li>We may update this Privacy Policy periodically.</li>
            </ul>
          </section>

          <div className="text-left mt-5">
            If you have any questions or concerns about your privacy, you can
            reach us at privacy@wearehumanoid.com.
          </div>
        </div>
      </Page>
    </>
  );
};

export default PrivacyPolicy;

import * as React from "react";
import Page from "../components/page";
import Background from "./assets/background.png" 
import Detail from "../components/detail";

import Pentops from "./assets/pentops.png";
import Powder from "./assets/powder.png";
import Coversticks from "./assets/coversticks.png";
import Eyeliner from "./assets/eyeliner.png";
import Liquidliner from "./assets/liquidliner.png";
import SprayDevice from "./assets/scarydevice.png";



const Ingredients = () => {
  return (
    <>
      <Page backgroundImage={Background}>
        <div>
	  <div className="flex flex-col items-center">
            <div className="text-sm md:text-xl bg-neonGreen w-max p-2 mb-4">
              Product Ingredients
            </div>
            <div className="text-3xl">
              What is in it?
            </div>
	    <div className="pt-4">
              Covid restrictions delayed production of our own beauty products, so we sent you third party products. Here is a list of ingredients, which are not representative of what we’re producing. 
	    </div>
	  </div>
	  <div className="pt-6">
            <Detail summary="All over cover sticks - vegan" colorHex="#2E3192" size="small">
              <div className="text-left p-6">
                <div className="font-bold">Island Punch, limetime, serpent</div>
                <img alt="" src={Pentops} />
                <div className="font-bold">Ingredients:</div>
	        <div> Ricinus Communis (Castor) Seed Oil, Cetearyl Ethylhexanoate, Ozokerite, Trimethylolpropane Triisostearate, Aluminum Starch Octenylsuccinate, Triethylhexanoin, Silica, Paraffin, Copernicia Cerifera (Carnauba) Wax, Glycerin, Retinyl Palmitate, Tocopheryl Acetate, Ascorbic Acid, Tocopherol Oil, BHT, Water (Aqua), [+/- (May Contain): Calcium Sodium Borosilicate, Calcium Aluminum Borosilicate, Tin Oxide, Silica, Palmitic Acid, Synthetic Fluorphlogopite, Mica (CI 77019), Titanium Dioxide (CI 77891), Iron Oxides (CI 77491, CI 77492, CI 77499), Bismuth Oxychloride (CI 77163), Carmine (CI 75470), Blue 1 Lake (CI 42090), Orange 5 Lake CI (45370), Red 6 (CI 15850), Red 7 Lake (CI 15850), Red 21 Lake (CI 45380), Red 22 Lake (CI 45380), Red 27 Lake (CI 45410), Red 28 Lake (CI 45410), Red 30 Lake (CI 73360), Red 33 Lake (CI 17200), Red 36 (CI 12085), Red 40 Lake (CI 16035), Yellow 5 Lake (CI 19140), Yellow 6 Lake (CI 15985), Yellow 10 Lake (CI 47005)].</div>
              </div>
            </Detail>
	  </div>

	  <div className="pt-2">
            <Detail summary="Body glitter" colorHex="#2E3192" size="small">
              <img alt="" src={Powder} />
	      <div className="text-left p-6">
                <div className="font-bold">Ingredients:</div>
	        <div>Polyethylene Terephthalate, Acrylates Copolymer in Crystallina Shades.</div>
	        <div className="pt-6">Not for eye area</div>
	      </div>
            </Detail>
          </div>

	  <div className="pt-2">
            <Detail summary="All over cover sticks - non vegan" colorHex="#2E3192" size="small">
              <div className="text-left p-6">
                <div className="font-bold">Synergy, Coral Gable</div>
                <img alt="" src={Coversticks} />
                <div className="font-bold">Ingredients:</div>
	        <div>Isononyl Isononanoate, Isonoanoic Acid, Cetyl Dimethicone, Polymethylsilsesquioxane, Octyldodecanol, Polyethylene, Synthetic Wax, Zinc Stearate, Talc, Dimethicone, Silica, Retinyl Palmitate, Tocopherol Oil, Chlorite, Dolomite, Magnesite, Water (Aqua), [+/- (May Contain): Mica (CI 77019), Titanium Dioxide (CI 77891), Bismuth Oxychloride (CI 77163), Carmine (CI 75470), Manganese Violet (CI 77742), Blue 1 Lake (CI 42090), Orange 5 (CI 45370), Orange 5 Lake (CI 45370), Red 6 (CI 15850), Red 7 Lake (CI 15850), Red 21 (CI 45380), Red 21 Lake (CI 45380), Red 22 Lake (CI 45380), Red 27 (CI 45410), Red 27 Lake (CI 45410), Red 28 Lake (CI 45410), Red 30 Lake (CI 73360), Red 33 Lake (CI 17200), Red 36 (CI 12085), Red 40 Lake (CI 16035), Yellow 5 Lake (CI 19140), Yellow 6 Lake (CI 15985), Yellow 10 Lake (CI 47005), Iron Oxides (CI 77491, CI 77492, CI 77499)].</div>
              </div>
            </Detail>
          </div>

	  <div className="pt-2">
            <Detail summary="Eye liner pencil" colorHex="#2E3192" size="small">
              <div className="text-left p-6">
                <div className="font-bold">Limestone, diamond</div>
                <img alt="" src={Eyeliner} />
                <div className="font-bold">Ingredients:</div>
	        <div>Cyclopentasiloxane, Isododecane, Polybutene, Synthetic Wax, Simmondsia Chinensis (Jojoba) Seed Oil, Hydrogenated Cottonseed Oil, Ceresin, Ozokerite, Silica, Microcrystalline Wax ( Cera Microcristallina), Tocopherol, Polyurethane-11, Ascorbyl Palmitate, Phenoxyethanol, [+/- (May Contain): Calcium Sodium Borosilicate, Calcium Aluminum Borosilicate, Polyethylene Terephthalate, Tin Oxide, Titanium Dioxide (CI 77891), Iron Oxides (CI 77491, CI 77492, CI 77499), Mica (CI 77019), Carmine (CI 75470), Ultramarine (CI 77007), Chromuim Oxide Green (CI 77288), Ferric Ferrocyanide (CI 77510), Bronze Powder (CI 77000), Aluminum Powder (CI 77000)].</div>
              </div>
            </Detail>
          </div>

	  <div className="pt-2">
            <Detail summary="Liquid liners" colorHex="#2E3192" size="small">
              <div className="text-left p-6">
                <div className="font-bold">blue diamond(v), mermaid(v), white lightning(v), airwave, gemini, sonar</div>
                <img alt="" src={Liquidliner} />
                <div className="font-bold">Ingredients:</div>
	        <div>Isododecane, Trimethylsiloxysilicate, Bis-Vinyl Dimethicone/Dimethicone Copolymer, Cyclopentasiloxane, Dimethicone, Polyethylene, Trihydroxystearin, Hydrogenated Polyisobutene, Phenyl Trimethicone, Hydroxypropyl Bispalmitamide MEA, [+/- (May Contain): Calcium Sodium Borosilicate, Calcium Aluminum Borosilicate, Silica, Tin Oxide, Mica (CI 77019), Titanium Dioxide (CI 77891), Iron Oxides (CI 77491, CI 77492, CI 77499), Bismuth Oxychloride (CI 77163), Carmine (CI 75470), Ultramarines (CI 77007), Chromium Oxide Greens (CI 77288), Chromium Hydroxide Green (CI 77289), Ferric Ferrocyanide (CI 77510), Manganese Violet (CI 77742), Blue 1 Lake (CI 42090), Yellow 5 Lake (CI 19140)].</div>
              </div>
            </Detail>
          </div>

	  <div className="pt-2">
            <Detail summary="Felt Tip Liquid Liner" colorHex="#2E3192" size="small">
              <div className="text-left p-6">
                <div className="font-bold">black</div>
                <img alt="" src={SprayDevice} />
                <div className="font-bold">Ingredients:</div>
	        <div>Water (Aqua), Methylpropanediol, Styrene/Acrylates Copolymer, Styrene/Acrylates/Ammonium Methacrylate Copolymer, Cellulose, PEG-60 Hydrogenated Castor Oil, Coco-Glucoside, Poloxamer 407, Citric Acid, Phenoxyethanol, Potassium Sorbate, Phenylpropanol, Sodium Benzoate, Caprylyl Glycol, Sodium Laureth-12 Sulfate, Sodium Lauryl Sulfate, Sodium Dehydroacetate, BHT, Benzyl Alcohol, Black-2 (CI 77266) (nano).</div>
              </div>
            </Detail>
          </div>

	  <div className="pt-2">
            <Detail summary="Liquid metLiquid metallic all over color - vegan" colorHex="#2E3192" size="small">
              <div className="text-left p-6">
                <div className="font-bold">Zodiac/Black cat</div>
                <img alt="" src={SprayDevice} />
                <div className="font-bold">Ingredients:</div>
	        <div>Liquid: Water (Aqua), Glycerin, Hydroxyethyl Acrylates/Sodium Acryloyldimethyl Taurate Copolymer, Squalane, Polysorbate 60, Triethanolamine, Carbomer, Phenoxyethanol, Ethylhexylglycerin, Potassium Sorbate, Hexylene Glycol, Caprylyl Glycol, Sorbitan Isostearate, [ +/- (May Contain): Calcium Sodium Borosilicate, Calcium Aluminum Borosilicate, Silica, Synthetic Fluorphlogopite, Tin Oxide, Mica (CI 77019), Titanium Dioxide (CI 77891), Bismuth Oxychloride (CI 77163), Carmine (CI 75470), Ultramarines (CI 77007), Chromium Oxide Greens (CI 77288), Chromium Hydroxide Green (CI 77289), Manganese Violet (CI 77742), Ferric Ferrocyanide (CI 77510), Iron Oxides (CI 77491, CI 77492, CI 77499)].</div>
                <div className="font-bold pt-5">solid product:</div>
	        <div>Isododecane, Trimethylsiloxysilicate, Dimethicone, Cyclopentasiloxane, Cyclotetrasiloxane, Trihydroxystearin, Disteardimonium Hectorite, Propylene Carbonate, Tocopheryl Acetate, Phenoxyethanol, Ethylhexylglycerin, [+/-(May Contain): Mica (CI 77019), Titanium Dioxide (CI 77891), Iron Oxides (CI 77491, CI 77492, CI 77499), Bismuth Oxychloride (CI 77163), Blue 1 Lake (CI 42090), Orange 5 Lake (CI 45370), Red 6 Lake (CI 15850), Red 7 Lake (CI 15850), Red 21 Lake (CI 45380), Red 22 Lake (CI 45380), Red 27 Lake (CI 45410), Red 28 Lake (CI 45410), Red 30 Lake (CI 73360), Red 33 Lake (CI 17200), Red 36 (CI 12085), Red 40 Lake (CI 16035), Yellow 5 Lake (CI 19140), Yellow 6 Lake (CI 15985), Yellow 10 Lake (CI 47005)].</div>
              </div>
            </Detail>
          </div>

        </div>
      </Page>
    </>
  );
};

export default Ingredients;

import * as React from "react";
import { Routes, Route, Link } from "react-router-dom";
import "./App.css";

import Header from "./components/header";
import Footer from "./components/footer";

// Routes
import Home from "./home";
import Instructions from "./instructions";
import TheApp from "./theapp";
import Ingredients from "./ingredients";
import FAQ from "./faq";
import TOS from "./tos";
import PrivacyPolicy from "./privacypolicy";
import AppPrivacyPolicy from "./appprivacypolicy";

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/instructions" element={<Instructions />} />
          <Route path="/the-app" element={<TheApp/>} />
          <Route path="/ingredients" element={<Ingredients/>} />
          <Route path="/faq" element={<FAQ/>} />
          <Route path="/tos" element={<TOS/>} />
          <Route path="/privacy" element={<PrivacyPolicy/>} />
          <Route path="/app-privacy" element={<AppPrivacyPolicy/>} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;

import * as React from "react";
import { ReactComponent as Arrow } from "../assets/arrow.svg";
type Props = {
  summary: string;
  size: "small" | "large";
  colorHex: string;
  children: any;
};

const Detail: React.FC<Props> = ({ summary, children, colorHex, size }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const getColorSize = (size: string) => {
    switch (size) {
      case "large":
        return "w-10 h-10";
      case "small":
        return "w-3 h-3 ml-3";
    }
  };
  return (
    <div
      className={`${
        isOpen ? "bg-white" : "bg-[#EEEEEE]"
      } border rounded-3xl border-gray-300 cursor-pointer`}
      onClick={() => setIsOpen(!isOpen)}
    >
      <summary className="flex items-center justify-between p-1">
        <div className="flex items-center">
          <div
            style={{ backgroundColor: colorHex }}
            className={`${getColorSize(size)} inline-block rounded-full mr-4`}
          ></div>
	    <div className="py-2">
              {summary}
	    </div>
        </div>
        <Arrow className="mr-4" />
      </summary>
      {isOpen && <div>{children}</div>}
    </div>
  );
};

export default Detail;

import * as React from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import Background from "./assets/background.png" ;
import HeroImage from "./assets/HeroImageWeb.png";
import ProductImage from "./assets/ProductImage.png";
import StencilImage from "./assets/StencilImage.png";
import NFC_Connection_Animation from "./assets/NFCConnectionAnimation.mp4";
import { ReactComponent as Arrow } from "./assets/arrow.svg";

import Page from "../components/page";


const Instructions = () => {
  return (
    <>
      <Page backgroundImage={Background}>
        <div className="flex justify-center md:h-full">
            <div className="relative max-w-[1440px]">
              <div className=" flex flex-col justify-between md:flex-row-reverse">
                <img alt="" src={HeroImage} className="self-center w-1/2" />
                <div className="flex md:w-1/2 justify-center items-center py-7 md:pr-20">
                  <div className="flex flex-col items-center">
                    <div className="text-sm md:text-xl bg-neonGreen w-max p-2 mb-4">
                      Instructions
                    </div>
                    <div className="text-3xl text-white px-16 md:px-0 md:text-6xl">
                     It's time to play! Quick and easy looks that don't require skill or precision, in ten minutes or less. What will you create?
                    </div>
                    <Arrow className="mt-5 md:hidden" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div className="flex bg-white p-4 text-left md:px-[72px]">
          <div className="flex flex-col md:w-1/2">
            <div className="text-humanoidPurple text-3xl pb-4">
              <div className="w-full mt-4 md:w-1/2">
                Be your own canvas
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <div className="mb-4">
               Your beta test kit contains 16 connected color products, 4 brushes, and 4 dot makers. The connected products work in conjunction with the humanoid app which provides inspiration and guidance in creating fun, easy, quick looks. Expressing yourself with color has never been this easy!
              </div>
              <div className="md:hidden md:mb-4">
                <ReactPlayer url={NFC_Connection_Animation} loop={true} playing={true}  style={{borderWidth:0}} width="100%" height="100%"/>
              </div>
              <div className="font-bold mt-8 mb-4">Connecting your products</div>
              <div className="mb-4">
                You can easily connect the color product you are interested in by tapping the NFC tag at the top of your phone screen around the camera area. You first need to download the humanoid app - you will find the download instructions in the app section of this site.
              </div>
              <div className="mb-4">
                Connecting the color products with the app will automatically pull up all the looks we have available for those products and colors, making it quicker to find inspiration so you can spend more time creating the look!
              </div>
            </div>
          </div>
          <div className="hidden mb-4 md:w-1/2 md:flex">
            <ReactPlayer url={NFC_Connection_Animation} loop={true} playing={true} style={{borderWidth:0}} width="100%" height="100%"/>
          </div>
        </div>
        <div className="bg-white text-left px-4 pb-8 md:px-[72px]">
          <div className="border-t border-black bg-white" />
          <div className="bg-white text-3xl text-left py-4 md:text-xl md:pt-2">
            Your connected color products
          </div>  
          <div className="flex bg-white md:pt-2">
            <div className="hidden md:w-full md:flex">
                <img alt="" src={ProductImage} className="self-center w-3/4" />
            </div>
            <div className="flex flex-col md:pr-8 md:w-1/2">
              <div className="flex">
              For our beta test we wanted to give you a wide range of color products 
              and formulas to play with so you can fully experience the humanoid system
              of connected products with the app. These products are prototypes and we'll 
              be using the outcomes of this beta test to further fine-tune our final products.
              </div>
              <div className="flex md:hidden mt-5">
                <img alt="" src={ProductImage} className="self-center w-full" />
              </div>

              <div className="flex flex-col mt-5 pt-4 text-left">
                <div className="font-bold text-humanoidPurple">Color products overview</div>
                <ol className="list-none" >
                  <li><span className="font-bold">A:{" "}</span>3 all over color sticks</li>
                  <li><span className="font-bold">B:{" "}</span>6 liquid liner</li>
                  <li><span className="font-bold">C:{" "}</span>1 body glitter</li>
                  <li><span className="font-bold">D:{" "}</span>2 eye liner pencils</li>
                  <li><span className="font-bold">E:{" "}</span>1 metallic paint</li>
                  <li><span className="font-bold">F:{" "}</span>1 felt tip liquid liner</li>
                  <li><span className="font-bold">G:{" "}</span>2 chubby all over color sticks</li>
                </ol>
              </div>
              <div className="flex flex-col mb-8 pt-3 ">
                <Link to="../ingredients/" className="rounded-full h-[53px] w-[200px] self-center md:self-start">
                  <button type="button" className="mt-7 text-white bg-black font-bold rounded-full h-[53px] w-[200px]" >
                          see ingredients
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white text-left px-4 pb-8 md:px-[72px]">
          <div className="border-t border-black bg-white" />
          <div className="bg-white text-3xl text-left py-4 md:text-xl md:pt-2">
            Your tools
          </div>  
          <div className="flex bg-white md:pt-2">
            <div className="hidden md:w-full md:flex">
                <img alt="" src={StencilImage} className="self-center w-3/4" />
            </div>
            <div className="flex flex-col md:pr-8 md:w-1/2">
              <div className="mb-4">
              We have also included tools (stencils, brushes, dot makers, masking tape) 
              that will help you with looks that require a bit more precision or repitition.
              </div>
              <div className="mb-4">
              The custom humanoid stencils are meant to be applied directly to your face and
              are one time use.
              </div>
              <div className="flex md:hidden mt-5">
                <img alt="" src={StencilImage} className="self-center w-full" />
              </div>
              <div className="flex flex-col mt-5 pt-4 text-left">
                <div className="font-bold text-humanoidPurple">Tools overview</div>
                <ol className="list-none" >
                  <li><span className="font-bold">A:{" "}</span>11 Stencils</li>
                  <li><span className="font-bold">B:{" "}</span>4 brushes</li>
                  <li><span className="font-bold">C:{" "}</span>4 dot makers</li>
                  <li><span className="font-bold">D:{" "}</span>1 masking tape</li>
                </ol>
              </div>
              <div className="flex flex-col mb-8 pt-3 ">
              </div>
            </div>
          </div>
        </div> 
      </Page>
    </>
  );
};

export default Instructions;

import * as React from "react";
import Page from "../components/page";
import Background from "./assets/background.png"

const TOS = () => {
  return (
    <>
      <Page backgroundImage={Background}>
        <div className="bg-white pl-3 pt-3 md:pl-[70px] md:pr-[454px] md:py-[54px]">
          <div className="text-sm md:text-xl bg-neonGreen w-max p-2">
           Terms of use
          </div>
	  <div className="text-3xl text-left pt-4">
            Humanoid Program Terms
	  </div>
	  <div className="text-left pt-4">
    Welcome to the humanoid Program! By participating in this Beta Program, you will receive access to the app and 16 colors, 4 brushes, 11 stencils, 1 skin safe tape, 4 acrylic dot makers (each, a “Product”) from Humanoid Labs, Inc. (“humanoid”). By clicking “I accept” below, you agree to the following terms, which govern your participation in the Beta Program. If you are under 18 years old, please ask your parent or guardian to review these terms and agree to them on your behalf.
	  </div>
	  <div className="text-left pt-4">
    To use the Product as intended, follow the instructions in the make-up look guides in the humanoid app.
	  </div>
	  <div className="text-left pt-4">
    Please note: The Product has not been commercially released for sale by humanoid, and humanoid has no obligation to move forward with that type of commercial release. The Product is not in final form and may contain errors, design flaws or other problems. Further testing, modification and development may be necessary to make the Product functional. humanoid has the right to abandon development of the Product at any time and without any obligation or liability to you.
	  </div>
	  <div className="text-left mt-5">
    The Product is provided “AS IS” and “AS AVAILABLE”, and use of the Product is at your own risk. To the extent permitted by law, humanoid disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement and those arising from a course of dealing or usage of trade, and you agree that humanoid’s liability to you arising out of your use of the Product will not exceed $100.
	  </div>
	  <div className="text-left mt-5">
    As participants of the Beta Program, we want you to use the Products according to your personal preference, and we encourage you to share your experience via social media. When you share or post on social media, please tag us @wearehumanoid so we can see how our Products work for you! However, you may not share details from the Beta Program outside of your personal and/or private social media channels and our humanoid Discord. Any feedback that you provide to humanoid regarding the Product (“Feedback”) is the sole property of humanoid, and you shall assign and hereby assign to humanoid all intellectual property rights you have in such Feedback (if any).
	  </div>
	  <div className="text-left mt-5">
    During and after the Beta Program, humanoid may use statistical data collected from or about an individual but which does not identify the individual personally for purposes of enhancing the Products, aggregated statistical analysis, technical support and other business purposes.
          </div>
        </div>
      </Page>
    </>
  );
};

export default TOS;
